import darkModeCookieName from './darkModeCookieName';
import autoModeCookieName from './autoModeCookieName';
import {
  darkModeManualInvertClass,
  darkModeIgnoreInlineStylesClass,
} from './darkReaderOptions';
import { enableDarkMode, enableAutoMode } from './enable';
import { useAppearance } from './hooks/useAppearance';
import { Mode } from './modes';
import { eventName } from './eventName';

const useDarkModeToggleListener = useAppearance;

export {
  darkModeCookieName,
  darkModeIgnoreInlineStylesClass,
  darkModeManualInvertClass,
  enableAutoMode,
  enableDarkMode,
  eventName,
  autoModeCookieName,
  useAppearance,
  useDarkModeToggleListener, // delete this once isDarkMode usages are refactored out
  Mode,
};
