import { enable, auto } from 'darkreader';

import { darkReaderOptions } from './darkReaderOptions';

export const enableDarkMode = () => {
  enable(
    {
      brightness: 100,
      contrast: 100,
      sepia: 0,
    },
    darkReaderOptions
  );
};

export const enableAutoMode = () => {
  auto(
    {
      brightness: 100,
      contrast: 100,
      sepia: 0,
    },
    darkReaderOptions
  );
};
export default enableDarkMode;
