type ToastListener = (type: string, text: string) => void;

let toastListeners: Array<ToastListener> = [];

export const addToastListener = (callback: ToastListener): void => {
  toastListeners.push(callback);
};

export const removeToastListener = (callback: ToastListener): void => {
  toastListeners = toastListeners.filter(cb => cb !== callback);
};

export const notifyToastListeners = (type: string, text: string): void => {
  toastListeners.forEach((listener: any) => listener(type, text));
};

export default {
  addToastListener,
  notifyToastListeners,
  removeToastListener,
};
