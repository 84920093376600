/* global analytics pendo trackingData ga */

const UserTracking = {
  initPendo() {
    pendo.initialize({
      visitor: trackingData.pendoVisitorMetadata,
      account: trackingData.pendoAccountMetadata,
    });
  },
  segmentEnabled() {
    return !(typeof analytics === 'undefined');
  },
  pendoEnabled() {
    return !(typeof pendo === 'undefined');
  },
  sendEvent(category, action, label, callback = undefined) {
    UserTracking.sendGAEvent(category, action, label, callback);
    UserTracking.sendPendoEvent(category, action, label);
  },
  sendGAEvent(category, action, label, callback) {
    if (typeof ga === 'undefined') {
      if (typeof callback === 'function') {
        callback();
      }
      return;
    }
    if (ga && callback === undefined) {
      ga('send', 'event', category, action, label);
    } else if (ga && callback !== undefined) {
      ga('send', 'event', category, action, label, { hitCallback: callback });
    }
  },
  sendPendoEvent(event, action, label) {
    if (UserTracking.pendoEnabled() && !(typeof pendo.track === 'undefined')) {
      pendo.track(event, { action, label });
    }
  },
  sendPendoEventProperties(name, data) {
    if (UserTracking.pendoEnabled() && !(typeof pendo.track === 'undefined')) {
      pendo.track(name, data);
    }
  },
  updatePendoVisitor(updatedData) {
    if (UserTracking.isTrackingDataNotLoaded()) {
      return;
    }
    pendo.updateOptions({
      visitor: {
        ...trackingData.pendoVisitorMetadata,
        ...updatedData,
      },
      account: trackingData.pendoAccountMetadata,
    });
  },
  isTrackingDataNotLoaded() {
    return (
      typeof trackingData === 'undefined' ||
      trackingData === null ||
      trackingData === undefined
    );
  },
  logout() {
    if (UserTracking.segmentEnabled()) {
      analytics.reset();
    }
  },
};

UserTracking.init = () => {
  const $body = $('body');

  $body.on('click', '.logout', UserTracking.logout);

  if (UserTracking.isTrackingDataNotLoaded()) {
    return;
  }

  if (UserTracking.pendoEnabled()) {
    UserTracking.initPendo();
  }
};

export default UserTracking;
