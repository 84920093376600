import Pluralize from 'pluralize';
import isValidDomain from 'is-valid-domain';
import get from 'lodash/get';
import Cookies from 'universal-cookie';
import zxcvbn from 'zxcvbn';
import { auto, disable as disableDarkMode } from 'darkreader';
import ToasterService from '@wpengine/unicorn/components/UnicornToasts/ToasterService';
import BannerAnnouncementService from '@wpengine/unicorn/components/Banner/BannerAnnouncementService';
import {
  autoModeCookieName,
  darkModeCookieName,
  enableAutoMode,
  enableDarkMode,
  eventName as toggleAppearanceEventName,
} from '@wpengine/unicorn/components/DarkMode';
import installs from '../modules/installs/installs';
import FieldValidations from '../modules/utilities/FieldValidations';
import RemoteLoadHtml from '../modules/utilities/RemoteLoadHtml';
import UserTracking from '../modules/utilities/UserTracking';
import { fetchEnvironment } from '../commons/helpers/Settings';
import '../images/index';

global.FieldValidations = FieldValidations;
global.Installs = installs;
global.lodash = { get };
global.Pluralize = Pluralize;
global.RemoteLoadHtml = RemoteLoadHtml;
global.UserTracking = UserTracking;
global.isValidDomain = isValidDomain;
global.zxcvbn = zxcvbn;
global.ToasterService = ToasterService;
global.BannerAnnouncementService = BannerAnnouncementService;

const toggleAppearanceMode = event => {
  if (event.detail === 'dark') {
    auto(false);
    enableDarkMode();
  } else if (event.detail === 'auto') {
    enableAutoMode();
  } else {
    disableDarkMode();
    auto(false);
  }
};
let intervalId;

function stopEmitting() {
  if (intervalId) {
    clearInterval(intervalId);
  }
}

function startEmitting(mode) {
  intervalId = setInterval(() => {
    const event = new CustomEvent('dm-cookie-update', {
      detail: mode,
    });
    document.dispatchEvent(event);
  }, 1000);

  setTimeout(() => {
    stopEmitting();
  }, 20000);
}

const saveUserAppearancePreferenceToDbAndDeleteCookies = mode => {
  /*
    Dark mode has been moved from being saved on browser side to being persisted on backend.
    If user previously turned on dark mode before this change, we save their preference
    to db and then delete the cookie so that update only runs once.

    Skip this if user is impersonating. Otherwise the impersonator's preference will be saved as the
    impersonated user's preference to the db.
  */
  if (document.querySelector("[data-impersonating='true']")) return;
  $.ajax({
    url: '/users',
    type: 'PATCH',
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      user: {
        appearance_setting: mode,
      },
    }),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
    },
    success() {
      cookies.remove(autoModeCookieName);
      cookies.remove(darkModeCookieName);
      const element = document.querySelector('[data-appearance-mode]');
      if (element && element instanceof HTMLElement) {
        element.dataset.appearanceMode = mode;
      }
      startEmitting(mode);
      document.addEventListener('stop-dm-cookie-update', stopEmitting);
    },
  });
};

document.addEventListener(toggleAppearanceEventName, toggleAppearanceMode);
const cookies = new Cookies();

if (
  cookies.get(darkModeCookieName) !== 'true' &&
  cookies.get(autoModeCookieName) !== 'true'
) {
  const appearanceSetting = document
    .querySelector('[data-appearance-mode]')
    ?.getAttribute('data-appearance-mode');

  if (appearanceSetting === 'dark') {
    enableDarkMode();
  }
  if (appearanceSetting === 'auto') {
    enableAutoMode();
  }
}

$(() => {
  if (cookies.get(darkModeCookieName) === 'true') {
    enableDarkMode();
    saveUserAppearancePreferenceToDbAndDeleteCookies('dark');
  } else if (cookies.get(autoModeCookieName) === 'true') {
    enableAutoMode();
    saveUserAppearancePreferenceToDbAndDeleteCookies('auto');
  }
  global.environment = fetchEnvironment();
  FieldValidations.init();
  installs.init();
  window.lodash = {
    get,
  };
  RemoteLoadHtml.init();
  UserTracking.init();
});
