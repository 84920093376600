/* eslint-disable no-var, vars-on-top */
declare global {
  var environment: environments;
}

export const fetchEnvironment = (): environments => {
  const env = process.env.NODE_ENV as environments;
  return env || ('production' as environments);
};

export const getSettingsMap = () => {
  if (!global.environment) {
    return SettingsMap[fetchEnvironment()];
  }
  return SettingsMap[global.environment];
};
