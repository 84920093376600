import { checkboxClasses, radioClasses, skeletonClasses } from '@mui/material';
import { DynamicThemeFix, setFetchMethod } from 'darkreader';

export const darkModeManualInvertClass = 'dm-manual-invert';
export const darkModeIgnoreInlineStylesClass =
  'dm-ignore-inline-style-include-children';

const cssOverrides = `
.marketing-page .growth-suite {
  background-image: none;
}

.Mui-disabled, button:disabled, input:disabled + .Tile-tile {
  filter: grayscale(1) opacity(.55) !important;
  opacity: 1 !important;
}

`;
setFetchMethod(window.fetch);

export const darkReaderOptions: DynamicThemeFix = {
  ignoreInlineStyle: [
    `.${darkModeIgnoreInlineStylesClass}`,
    `.${darkModeIgnoreInlineStylesClass} *`,
    `.${darkModeManualInvertClass}`,
    `.${darkModeManualInvertClass} *`,
    `.${checkboxClasses.root}`,
    `.${radioClasses.root}`,
    `.${skeletonClasses.root}`,
  ],
  // these overrides should be handled at the app level, Unicorn shouldn't know about the growth-suite class
  css: cssOverrides,
  ignoreImageAnalysis: [],
  invert: [
    `.${darkModeManualInvertClass}`,
    `.${checkboxClasses.root}`,
    `.${radioClasses.root}`,
    `.${skeletonClasses.root}`,
  ],
  disableStyleSheetsProxy: false,
};

export default darkReaderOptions;
