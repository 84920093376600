/* eslint-disable comma-dangle */
/* global WpEngine FieldValidations UserTracking */
/* eslint camelcase: 2, object-shorthand: 2, comma-dangle: 2, no-param-reassign: 2 */
const Installs = {
  setupContent: () => {
    WpEngine.afterLoadSetup();
    Installs.setupPasswordEdit();
  },
  setupNewForm: () => {
    WpEngine.afterLoadSetup();
  },
  watchNewForm: () => {
    $('.add-install-form').on(
      'change',
      "input[name='install[transferable]']",
      event => {
        const $form = $(event.target).closest('form');
        $form.toggleClass('is-transferable', event.target.checked);
      }
    );

    $('.add-install-form').on(
      'change',
      "select[name='install[source_install_name]']",
      event => {
        const { target } = event;
        const isCopy = target.value.length > 0;
        const $form = $(target).closest('form');
        $form.toggleClass('is-copy', isCopy);
        $form.find('.source-install-name').toggleClass('last', !isCopy);
        Installs.verifyChecksums($(target));
      }
    );

    const $commitId = $('#commit_id');
    if ($commitId.exists()) {
      const $sourceInstall = $('#install_source_install_name');
      $sourceInstall.on('change', () => {
        const $backupPrompt = $('#commit_id').siblings('.hint');
        const sourceInstallName = $sourceInstall.val();

        const $backupLink = $backupPrompt.find('a');
        $backupLink.attr(
          'href',
          $backupLink.data('href').replace('SITE_NAME', sourceInstallName)
        );
        $backupPrompt.removeClass('hidden');
      });
      window.watchInstallDropdown($sourceInstall, $commitId);
    }

    $('body').on('submit', '.add-install-form form', () => {
      UserTracking.sendEvent('add_install', 'submit');
    });
  },
  watchForEnvironmentChanges: () => {
    const $installContainer = $('#page_container');
    if (!$installContainer) return;

    const submitEnvironmentForm = () => {
      $('#update_environment_form')
        .closest('form')
        .submit();
    };

    const initializeEnvironmentSelect = () => {
      const $envSelect = $('select#install_environment');
      $envSelect.data('original', $envSelect.val());
    };

    const resetEnvironmentSelect = () => {
      const $envSelect = $('select#install_environment');
      $envSelect.val($envSelect.data('original'));
    };

    const watchForEnvironmentChanges = event => {
      $(event.target)
        .closest('form')
        .submit();
    };

    const displayError = (event, xhr) => {
      WpEngine.displayResponseErrorMsg(xhr.responseText);
      resetEnvironmentSelect();
    };

    const toggleDisplay = () => {
      $('.env-edit').toggle();
      $('.env-display').toggle();
    };

    const ajaxSuccessEnv = () => {
      const $env = $('#install_environment option:selected').text();
      $('a[class=js-link-edit]').text($env);
      toggleDisplay();
      initializeEnvironmentSelect();
    };

    if ($('select#install_environment').exists()) {
      initializeEnvironmentSelect();
    }

    $installContainer.on(
      'click',
      '#change-environment-button',
      submitEnvironmentForm
    );
    $installContainer.on('click', '.btn-close', resetEnvironmentSelect);
    $installContainer.on(
      'click',
      'a[class=js-link-edit], a[class=js-link-close]',
      toggleDisplay
    );
    $installContainer.on(
      'ajax:success',
      '#update_environment_form',
      ajaxSuccessEnv
    );
    $installContainer.on(
      'ajax:error',
      '#update_environment_form, .js-link-edit a',
      displayError
    );
    $installContainer.on(
      'change',
      '#install_environment',
      watchForEnvironmentChanges
    );
  },
  setupPasswordEdit: () => {
    const $installContainer = $('#page_container');
    if (!$installContainer) return;

    const ajaxSuccessPP = (event, html) => {
      $installContainer
        .find('.panel.transferable-install')
        .replaceWith($(html));
      Installs.setupContent();
    };

    const showEditPassword = () => {
      $('.current-password').hide();
      $('.edit-password').show();
    };

    $installContainer.on(
      'click',
      '.panel.transferable-install a#edit_password',
      showEditPassword
    );
    $installContainer.on('ajax:success', '.password-content', ajaxSuccessPP);
  },
  verifyChecksums: $installSelector => {
    const installName = $installSelector.val();
    if (installName.length === 0) return;

    const $form = $installSelector.closest('form');
    const $warning = $('#checksums-warning');
    $('#form-invalidator').removeClass('failing');

    FieldValidations.checkForm($form);
    if (!$warning.hasClass('hidden')) {
      $warning.off(
        'click',
        '.details-expander',
        Installs.toggleChecksumsDetails
      );
      $warning.addClass('hidden');
    }

    const handleSuccess = data => {
      if (data.bad_checksums) {
        $('#form-invalidator').addClass('failing');
        $form.find('button, input[type=submit]').prop('disabled', true);
        const $details = $warning.find('.wp-cli-output');
        const html = data.details.replace(/\n/, '<br/>');
        $details.html(html);
        $warning.removeClass('hidden');
        $warning.on(
          'click',
          '.details-expander',
          Installs.toggleChecksumsDetails
        );
      }
    };

    const basePath = $installSelector.data('href');
    const path = `${basePath}?install_name=${installName}`;
    $.ajax({
      url: path,
      success: handleSuccess,
    });
  },
  toggleChecksumsDetails: () => {
    const $details = $('#checksums-warning').find('.wp-cli-output');
    $details.toggleClass('hidden');
  },
};

Installs.init = () => {
  const $installContainer = $('#page_container');
  if (!$installContainer) return;

  if ($('.add-install-form').exists()) {
    Installs.watchNewForm();
  }

  Installs.watchForEnvironmentChanges();
  Installs.setupPasswordEdit();

  const displayError = (event, xhr) => {
    WpEngine.displayResponseErrorMsg(xhr.responseText);
  };

  $installContainer.on('ajax:error', '.convert-to-standard-btn', displayError);

  const initialFormValidation = () => {
    FieldValidations.checkForm($('form.new-install'));
  };

  if ($('.add-install-form').exists()) {
    initialFormValidation();
  }

  const $source = $('#install_source_install_name');
  if ($source.exists() && $source.val().length > 0) {
    Installs.verifyChecksums($source);
  }
};

export default Installs;
