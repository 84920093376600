const RemoteLoadHtml = {
  beforeLoad() {
    $(this)
      .removeClass('loaded')
      .addClass('loading');
  },
  afterLoad() {
    $(this)
      .removeClass('loading')
      .addClass('loaded');
  },
  loadHTML(evt, html) {
    const $loadLink = $(this);
    const $target = $($loadLink.data('remote-load-html'));
    const $triggerSelector = $loadLink.data('trigger-selector');

    if ($loadLink.data('append')) {
      $target.append($(html).find($loadLink.data('append')));
    } else {
      $target.replaceWith(html);
    }
    $loadLink.trigger('remote-load:success');
    $($triggerSelector).trigger('remote-load:success');
  },
  errorLoadingHTML() {
    let $errorContainer;
    const $loader = $(this);
    if ($loader.data('remote-load-error') === false) {
      return;
    }

    $loader.data('html', $loader.html());

    if ($loader.data('error-container')) {
      $errorContainer = $($loader.data('error-container'));
    } else {
      $errorContainer = $loader;
    }

    if ($loader.data('remote-load-error')) {
      $errorContainer.replaceWith($loader.data('remote-load-error'));
    } else {
      $errorContainer
        .text('Sorry, something went wrong. Try again?')
        .addClass('alert-danger-text');
    }
    $loader.trigger('remote-load:error');
  },
  resetHTML() {
    const $loader = $(this);
    if ($loader.data('html')) {
      $loader.html($loader.data('html'));
      $loader.data('html', null).removeClass('alert-danger-text');
    }
  },
  remoteClick() {
    const $toggleLink = $(this);
    const selector =
      $toggleLink.data('remote-clicker') ||
      $toggleLink.data('remote-clicker-ajax');
    $(selector).trigger('click');
  },
};

RemoteLoadHtml.init = () => {
  const $body = $('body');

  $body.on('click', '[data-remote-clicker]', RemoteLoadHtml.remoteClick);
  $body.on(
    'ajax:success',
    '[data-remote-clicker-ajax]',
    RemoteLoadHtml.remoteClick
  );
  $body.on('ajax:before', '[data-remote-load-html]', RemoteLoadHtml.beforeLoad);
  $body.on('ajax:success', '[data-remote-load-html]', RemoteLoadHtml.loadHTML);
  $body.on(
    'ajax:error',
    '[data-remote-load-html]',
    RemoteLoadHtml.errorLoadingHTML
  );
  $body.on(
    'ajax:complete',
    '[data-remote-load-html]',
    RemoteLoadHtml.afterLoad
  );
  $body.on('click', '[data-remote-load-html]', RemoteLoadHtml.resetHTML);

  $body.find('a[data-remote-auto-load-html]').trigger('click');
  $body.find('form[data-remote-auto-load-html]').trigger('submit');
};

export default RemoteLoadHtml;
