/* global Forms */
import zxcvbn from 'zxcvbn';
/* Constants */
const bwlRegex = /^[a-zA-Z]/; // begins with letter
const olanRegex = /^[a-zA-Z0-9]*$/; // only letters and numbers

let validateInstallTimeout;

const FieldValidations = {
  isValidString: (str, regex) => str !== '' && regex.test(str),

  isValidLength: (str, $dataContainer) => {
    const { length } = str;
    const minLength = $dataContainer.data('min');
    const maxLength = $dataContainer.data('max');

    if (minLength && length < minLength) return false;
    if (maxLength && length > maxLength) return false;
    return true;
  },

  fetchNameAvailability: name =>
    $.ajax({
      url: '/install_available',
      dataType: 'json',
      data: {
        name,
      },
    }),

  isValidInstallName: (value, { bwlRe, olanRe, lengthData }) => {
    const bwl = FieldValidations.isValidString(value, bwlRe);
    const olan = FieldValidations.isValidString(value, olanRe);
    const length = FieldValidations.isValidLength(value, lengthData);

    if (!bwl || !olan || !length) return $.Deferred().reject();
    return FieldValidations.fetchNameAvailability(value);
  },

  isValidPassword: (value, { lengthData }) =>
    FieldValidations.isValidLength(value, lengthData),

  validateInstallName: e => {
    clearTimeout(validateInstallTimeout);

    const $input = $(e.target);
    const $localValidatorContainer = $input.closest('.validate-install-name');
    const $item = $localValidatorContainer.find('.install-name-available');
    const value = $input.val();

    FieldValidations.toggleItemState(
      $localValidatorContainer.find('.begins-with-letter'),
      FieldValidations.isValidString(value, bwlRegex)
    );
    FieldValidations.toggleItemState(
      $localValidatorContainer.find('.only-letter-and-numbers'),
      FieldValidations.isValidString(value, olanRegex)
    );
    FieldValidations.toggleItemState(
      $localValidatorContainer.find('.name-valid-length'),
      FieldValidations.isValidLength(
        value,
        $localValidatorContainer.find('.name-valid-length')
      )
    );

    if ($input.val().length < 3) {
      FieldValidations.setItemState($item, 'failing');
      return;
    }

    FieldValidations.setItemState($item, 'checking');

    const timeoutFunction = () => {
      FieldValidations.fetchNameAvailability($input.val())
        .then(res => FieldValidations.toggleItemState($item, res.available))
        .fail(() => FieldValidations.toggleItemState($item, false));
    };

    validateInstallTimeout = setTimeout(timeoutFunction, 500);
  },

  checkPasswordForNames: password => {
    let additionalErrors = '';
    const userNameItem = $('.user-name');
    if (userNameItem.length > 0) {
      const { firstName, lastName } = $('.user-name').data();
      const lowerPassword = password.toLowerCase();
      if (lowerPassword.includes(firstName.toLowerCase())) {
        additionalErrors += ' Password cannot contain your first name.';
      }
      if (lowerPassword.includes(lastName.toLowerCase())) {
        additionalErrors += ' Password cannot contain your last name.';
      }
    }
    return additionalErrors;
  },

  validatePasswordInput: e => {
    const $input = $(e.target);
    const $localValidatorContainer = $input.closest('.validate-user-password');
    const newPassword = $input.val();

    // zxcvbn scores can be 0-4. 4 is the strongest level.
    const scoreThreshold = $('div.zxcvbn_score_data').data()
      .zxcvbnScoreThreshold;
    const zxcvbnResult = zxcvbn(newPassword);
    const warning = $localValidatorContainer.find('.zxcvbn-warning');
    const suggestions = $localValidatorContainer.find('.zxcvbn-suggestions');

    const additionalErrors = FieldValidations.checkPasswordForNames(
      newPassword
    );

    const validLength = FieldValidations.isValidLength(
      newPassword,
      $localValidatorContainer.find('.password-valid-length')
    );
    const validScore =
      zxcvbnResult.score >= scoreThreshold && additionalErrors === '';

    FieldValidations.toggleItemState(
      $localValidatorContainer.find('.password-valid-length'),
      validLength
    );
    FieldValidations.toggleItemState(
      $localValidatorContainer.find('.zxcvbn-evaluation'),
      validScore
    );

    if (!validScore) {
      const suggestionsString = !zxcvbnResult.feedback.suggestions[0]
        ? ''
        : zxcvbnResult.feedback.suggestions[0];
      warning.text(zxcvbnResult.feedback.warning);
      suggestions.text(suggestionsString + additionalErrors);
    } else {
      warning.text('');
      suggestions.text('');
    }
  },

  validateSourceSelected: e => {
    const $input = $(e.target);
    const value = $input.val();
    const $localValidatorContainer = $input.closest(
      '.validate-source-selected'
    );
    FieldValidations.toggleItemState(
      $localValidatorContainer,
      value.length > 0
    );
  },

  checkForm: $form => {
    const failureSelector = 'li.failing, div.failing';
    Forms.toggleInvalid($form, $form.find(failureSelector).exists());
  },

  toggleItemState: ($item, passed) => {
    FieldValidations.setItemState($item, passed ? 'passing' : 'failing');
    FieldValidations.checkForm($item.closest('form'));
  },

  setItemState: ($item, className) => {
    $item.removeClass('failing passing checking');
    $item.addClass(className);
  },

  addFocusedClass: e => {
    const $this = $(e.target);
    if (!$this.hasClass('password-toggle')) {
      $this.closest('.field-validations-wrapper').addClass('focused');
    }
  },

  removeFocusedClass: e => {
    $(e.target)
      .closest('.field-validations-wrapper')
      .removeClass('focused');
  },

  checkPassword: e => {
    const $input = $(e.target);
    const value = $input.val();
    const opts = {
      lengthData: $input
        .closest('.validate-user-password')
        .find('.password-valid-length'),
    };

    if (!FieldValidations.isValidPassword(value, opts)) {
      $input.closest('.form-group').addClass('has-error');
    }
  },

  checkSiteName: e => {
    const $input = $(e.target);
    const value = $input.val();
    const opts = {
      bwlRe: bwlRegex,
      olanRe: olanRegex,
      lengthData: $input
        .closest('.validate-install-name')
        .find('.name-valid-length'),
    };

    FieldValidations.isValidInstallName(value, opts)
      .then(res => {
        if (!res.available) $input.closest('.form-group').addClass('has-error');
      })
      .fail(() => $input.closest('.form-group').addClass('has-error'));
  },

  validateAndDecorateFields: e => {
    FieldValidations.removeFocusedClass(e);
    switch (e.target.id) {
      case 'signup_password':
        FieldValidations.checkPassword(e);
        break;
      case 'signup_site_name':
        FieldValidations.checkSiteName(e);
        break;
      default:
        break;
    }
  },
};

FieldValidations.init = () => {
  const $body = $('body');
  $body.on(
    'keyup',
    '.validate-install-name input',
    FieldValidations.validateInstallName
  );
  $body.on(
    'keyup focus',
    '.validate-user-password input',
    FieldValidations.validatePasswordInput
  );
  $body.on(
    'change',
    '.validate-source-selected select',
    FieldValidations.validateSourceSelected
  );
  $body.on(
    'focus',
    '.field-validations-wrapper input, .field-validations-wrapper select',
    FieldValidations.addFocusedClass
  );
  $body.on(
    'blur',
    '.field-validations-wrapper input, .field-validations-wrapper select',
    FieldValidations.validateAndDecorateFields
  );
};

export default FieldValidations;
