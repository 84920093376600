type BannerAnnouncementListener = (type: string, text: string) => void;

let bannerAnnouncementListeners: Array<BannerAnnouncementListener> = [];

export const addBannerAnnouncementListener = (
  callback: BannerAnnouncementListener
): void => {
  bannerAnnouncementListeners.push(callback);
};

export const removeBannerAnnouncementListener = (
  callback: BannerAnnouncementListener
): void => {
  bannerAnnouncementListeners = bannerAnnouncementListeners.filter(
    cb => cb !== callback
  );
};

export const notifyBannerAnnouncementListeners = (text: string): void => {
  bannerAnnouncementListeners.forEach((listener: any) => listener(text));
};

export default {
  addBannerAnnouncementListener,
  removeBannerAnnouncementListener,
  notifyBannerAnnouncementListeners,
};
